import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Anchor } from "antd";
import "github-markdown-css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListIcon from '@mui/icons-material/List';
import { Box, CssBaseline, Divider, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

type markdownProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  isMobile: boolean
};

const drawerWidth = 276;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  mobile: boolean;
}>(({ theme, open , mobile}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${mobile?0:drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

type Title = {
  key: string;
  href: string;
  title: string;
  children?: Title[];
  nodeName: any;
};

const MarkDownPage: React.FC<markdownProps> = (props) => {
  const [show, setShow] = useState(false);
  const [md, handleMD] = useState("loading... ...");
  const [open, setOpen] = React.useState(false);
  const [titles, setTitles] = useState<Title[]>([]);
  const search = useLocation().search;
  const device =  search?queryString.parse(search):{"device":""};
  const isApp = device["device"] === "app";
  // const [isFix, setIsFix] = useState(false);
  useEffect(() => {
    fetch("/assets/Whitepaper.md")
      .then((resp) => resp.text())
      .then((txt) => handleMD(txt));
  }, []);

  useEffect(() => {
    // 获取到md数据后显示md内容和nav
    setTitles(addAnchor());
    setShow(true);
  }, [md, handleMD]);

  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /**
   * 格式化markdown标题的dom节点数组
   */
  const formatNavItem = (headerDom: NodeListOf<HTMLElement>) => {
    // 将NodeList转换为数组，并提取出需要的属性
    let headerArr = Array.prototype.slice
      .call(headerDom)
      .map((item, index) => {
        let title = {
          href: "#" + index,
          key: "" + index,
          title: headerDom[index].innerText,
          children: [],
          nodeName: item.nodeName,
        };
        return title;
      }) as Title[];

    /**
     * (双重循环，从后往前，逐渐将子节点存入父节点children属性)
     * 1. 从后往前，将子标题直接存入前一个父级标题的children[]中
     * 2. 如果前一个标题与当前标题(或标题数组)无直系关系，则直接将当前标题(或标题数组解构后)放入list数组
     * 3. 循环多次，直到result数组长度无变化，结束循环
     */
    let result = headerArr;
    let preLength = 0;
    let newLength = result.length;
    let num = 0;
    while (preLength !== newLength) {
      num++;
      preLength = result.length; // 获取处理前result数组长度
      let list: Title[] = []; // list数组用于存储本次for循环结果
      let childList: Title[] = []; // childList存储遍历到的兄弟标题，用于找到父标题时赋值给父标题的children属性
      for (let index = result.length - 1; index >= 0; index--) {
        if (
          // 当前节点与上一个节点是兄弟节点，将该节点存入childList数组
          result[index - 1] &&
          result[index - 1].nodeName.charAt(1) ===
          result[index].nodeName.charAt(1)
        ) {
          childList.unshift(result[index]);
        } else if (
          // 当前节点是上一个节点的子节点，则将该节点存入childList数组，将childList数组赋值给上一节点的children属性，childList数组清空
          result[index - 1] &&
          result[index - 1].nodeName.charAt(1) <
          result[index].nodeName.charAt(1)
        ) {
          childList.unshift(result[index]);
          result[index - 1].children = [
            ...(result[index - 1].children as []),
            ...childList,
          ];
          childList = [];
        } else {
          // 当前节点与上一个节点无直系关系，或当前节点下标为0的情况
          childList.unshift(result[index]);
          if (childList.length > 0) {
            list.unshift(...childList);
          } else {
            list.unshift(result[index]);
          }
          childList = [];
        }
      }
      result = list;
      newLength = result.length; // 获取处理后result数组长度
    }
    return result;
  };

  /**
   * markdown锚点注入方法
   */
  const addAnchor = () => {
    // 获取markdown标题的dom节点
    const header: NodeListOf<HTMLElement> = document.querySelectorAll(
      "h1, h2, h3, h4, h5, h6"
    );
    // 向标题中注入id，用于锚点跳转
    header.forEach((navItem, index) => {
      navItem.setAttribute("id", index.toString());
    });
    // 格式化标题数组，用于antd锚点组件自动生成锚点
    return formatNavItem(header);
  };

  /**
   * 锚点item点击事件
   * 1.解决antd的Anchor组件会在导航栏显示"#锚点id"的问题，
   * 2.以及本项目中navbar通过监听屏幕滚动进行定位，通过scrollIntoView设置页面滚动缓冲，可以一定程度上解决在页面快速滚动时navbar的定位切换造成的闪烁问题。
   * 当然也可以不设置该点击事件
   */
  const handleClickNavItem = (e: any, link: any) => {
    e.preventDefault();
    if (link.href) {
      // 找到锚点对应得的节点
      let element = document.getElementById(link.href);
      // 如果对应id的锚点存在，就跳滚动到锚点顶部
      element &&
      element.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ paddingTop: isApp?"24px":"0"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
          <ListIcon sx={{fontSize:'40'}}/>
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            White Paper V1.0
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={props.isMobile?"temporary":"persistent"}
        anchor="left"
        open={open}
        onClose={()=>{setOpen(false)}}
      >
        <DrawerHeader>
          <Typography variant="inherit" noWrap component="div">
            Outline
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div className={""}>
          <Anchor
            className='markdown-nav'
            affix={false}
            offsetTop={100} // 设置距离页面顶部的偏移
            onClick={handleClickNavItem}
            items={titles}
          />
        </div>
      </Drawer>
      <Main open={open} mobile={props.isMobile} >
        <DrawerHeader />
        {show && (<div className={"px-4"}>
          <ReactMarkdown remarkPlugins={[remarkGfm]} className={"markdown-body"}>{md}</ReactMarkdown>
        </div>)}
      </Main>
    </Box>
  )
};

export default MarkDownPage;
