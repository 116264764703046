import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";
import PdfWebPage from "./pages/PdfWeb";
import MobileSigma from "./pages/SigmaWeb/MobileSigma";
import MarkDownPage from "./pages/MarkdownPage";

const SIGMAWeb = React.lazy(() => import("pages/SigmaWeb"));

const ProjectRoutes = () => {
  let _isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) !=null;
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={_isMobile?<MobileSigma />:<SIGMAWeb />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/:md/:mdfile" element={<MarkDownPage isMobile={_isMobile}/>}/>
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
