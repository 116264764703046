import React from "react";

const sizeClasses = {
  txtInterSemiBold24WhiteA700: "font-inter font-semibold",
  txtInterRegular20: "font-inter font-normal",
  txtInterSemiBold48: "font-inter font-semibold",
  txtInterSemiBold24: "font-inter font-semibold",
  txtInterMedium16: "font-inter font-medium",
  txtInterSemiBold16: "font-inter font-semibold",
  txtInterSemiBold20BlueA70099: "font-inter font-semibold",
  txtInterSemiBold24Gray900: "font-inter font-semibold",
  txtInterSemiBold64: "font-inter font-semibold",
  txtInterSemiBold48WhiteA700: "font-inter font-semibold",
  txtInterSemiBold20: "font-inter font-semibold",
  txtInterRegular20Gray900a2: "font-inter font-normal",
  txtInterRegular24: "font-inter font-normal",
  txtInterRegular14: "font-inter font-normal",
  txtInterRegular20BlueA70099: "font-inter font-normal",
  txtInterRegular24WhiteA700a2: "font-inter font-normal",
  txtInterRegular16: "font-inter font-normal",
} as const;

export type TextProps = Partial<{
  className: string;
  size: keyof typeof sizeClasses;
  as: any;
}> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  size,
  as,
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
